<template>
  <b-tabs no-fade>
    <b-tab active>
      <template #title>
        <feather-icon class="mb-lg-none" icon="ClockIcon" />
        <span class="d-none d-lg-block">Programats</span>
      </template>
      <b-card>
        <transfers-table
          :items="scheduledTransfers"
          :loading="loading"
          show-search
          show-button
        />
      </b-card>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon class="mb-lg-none" icon="CheckSquareIcon" />
        <span class="d-none d-lg-block">Completats</span>
      </template>
      <b-card>
        <transfers-table
          :items="completedTransfers"
          :loading="loading"
          show-search
          show-button
          :default-sort-desc="true"
        />
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
import TransfersTable from "@/views/transfers/list/components/TransfersTable.vue";
import { getFullAddress, notifyError } from "@/utils/methods";
import { BCard, BTabs, BTab } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    TransfersTable,
  },
  computed: {
    loading() {
      return this.$store.getters["transfers/loading"];
    },
    today() {
      return this.$moment();
    },
    transfers() {
      return this.$store.getters["transfers/transfers"];
    },
    scheduledTransfers() {
      if (!this.transfers.length) return [];
      const transfers = this.transfers.filter((transfer) => {
        const transferPickupDate = this.$moment(transfer.pickupDate);
        return this.today.isSameOrBefore(transferPickupDate, "day");
      });
      return this.getMappedTransfers(transfers);
    },
    completedTransfers() {
      if (!this.transfers.length) return [];
      const transfers = this.transfers.filter((transfer) => {
        const transferPickupDate = this.$moment(transfer.pickupDate);
        return this.today.isAfter(transferPickupDate, "day");
      });
      return this.getMappedTransfers(transfers);
    },
  },
  created() {
    this.fetchTransfers();
  },
  methods: {
    fetchTransfers() {
      this.$store
        .dispatch("transfers/fetchTransfers")
        .catch(() =>
          notifyError(
            this.$t("errors.fetchTransfers.title"),
            this.$t("errors.fetchTransfers.description")
          )
        );
    },
    locationName(location) {
      return location?.name || null;
    },
    locationAddress(location) {
      return getFullAddress(location, this.$i18n.locale, true, true, false);
    },
    getPickupDateTimeString(date, time) {
      if (!date) return null;
      date = date.split("T")[0];
      if (!time) return date;
      return `${date} ${time}`;
    },
    getMappedTransfers(transfers) {
      if (!transfers.length) return [];
      return transfers.map((transfer) => ({
        uuid: transfer.uuid || this.$t("No definit"),
        localizator: transfer.booking?.localizator || this.$t("No definit"),
        bookingCancelled: transfer.booking?.cancelled || null,
        bookingUuid: transfer.booking?.uuid || null,
        source: transfer.booking?.source || this.$t("No definit"),
        highlighted: transfer.booking?.highlighted || false,
        accommodation:
          transfer.booking?.accommodation?.name || this.$t("No definit"),
        accommodationUuid: transfer.booking?.accommodation?.uuid || null,
        pickupDate: transfer.pickupDate || null,
        pickupTime: transfer.pickupTime || null,
        pickupDateTime:
          this.getPickupDateTimeString(
            transfer.pickupDate,
            transfer.pickupTime
          ) || null,
        passengers: transfer.passengers || this.$t("No definit"),
        pickupLocation:
          this.locationName(transfer.pickupLocation) ||
          this.locationAddress(transfer.pickupLocation),
        dropoffLocation:
          this.locationName(transfer.dropoffLocation) ||
          this.locationAddress(transfer.dropoffLocation),
        confirmed: transfer.confirmed || false,
        confirmedAt: transfer.confirmedAt || this.$t("No definit"),
        providerLocalizator: transfer.providerLocalizator || null,
      }));
    },
  },
};
</script>
