<template>
  <div class="clients-table">
    <!-- NEW BUTTON AND SEARCH -->
    <b-row
      v-if="showSearch || showButton"
      class="mb-2 d-flex flex-wrap"
      :class="{
        'justify-content-between': showButton,
        'justify-content-end': !showButton,
      }"
    >
      <b-col v-if="showButton" cols="3" md="auto" class="mb-1 mb-md-0">
        <b-button variant="primary" block @click.prevent="newItemButtonClicked">
          <feather-icon icon="PlusIcon" class="d-block d-md-none" />
          <span class="d-none d-md-inline">{{ $t("Nou transfer") }}</span>
        </b-button>
      </b-col>
      <b-col
        v-if="showSearch"
        cols="9"
        md="auto"
        class="mb-1 mb-md-0"
        :class="{
          'justify-content-between': showButton,
          'justify-content-end': !showButton,
        }"
      >
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loading"
        />
      </b-col>
    </b-row>

    <!-- TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          hover
          responsive
          class="position-relative"
          :busy="loading"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="items"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
          @row-clicked="onRowClicked"
          @row-contextmenu="onRowRightClicked"
          @row-middle-clicked="onRowMiddleClicked"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: pickupDateTime -->
          <template #cell(pickupDateTime)="data">
            <span class="text-nowrap">
              <feather-icon
                icon="CheckIcon"
                v-if="data.item.confirmed"
                class="text-success"
              />
              <feather-icon icon="SlashIcon" v-else class="text-danger" />
              <span class="ml-50">
                {{
                  getPickupDateTime(data.item.pickupDate, data.item.pickupTime)
                }}
              </span>
            </span>
          </template>

          <!-- Column: localizator -->
          <template #cell(localizator)="data">
            <b-booking-localizator
              :localizator="data.value"
              :source="data.item.source"
              :highlighted="data.item.highlighted"
              :fictitious="data.item.fictitious"
              :cancelled="data.item.bookingCancelled"
            />
          </template>

          <!-- Column: accommodation -->
          <template #cell(accommodation)="data">
            <b-accommodation
              :uuid="data.item.accommodationUuid"
              :name="data.value"
            />
          </template>

          <!-- Column: providerLocalizator -->
          <template #cell(providerLocalizator)="data">
            <span class="text-nowrap">{{ data.value }}</span>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loading"
      v-if="items.length > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BSpinner,
    BBookingLocalizator,
    BAccommodation,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showButton: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
    defaultSortBy: {
      type: String,
      default: "pickupDateTime",
    },
    defaultSortDesc: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: this.defaultSortBy,
      sortDesc: this.defaultSortDesc,
      perPage: 10,
      pageOptions: [5, 10, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      fields: [
        { key: "pickupDateTime", label: this.$t("Data"), sortable: true },
        { key: "localizator", label: this.$t("Reserva"), sortable: true },
        { key: "accommodation", label: this.$t("Allotjament"), sortable: true },
        {
          key: "providerLocalizator",
          label: this.$t("Localitzador"),
          sortable: true,
        },
        { key: "passengers", label: this.$t("Passatgers") },
        { key: "pickupLocation", label: this.$t("Orígen") },
        { key: "dropoffLocation", label: this.$t("Destí") },
      ],
      selectedTransfer: null,
    };
  },
  watch: {
    items(items) {
      this.totalRows = items.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowClicked(item) {
      if (!item.uuid) return;
      this.$router.push({
        name: "foravila-transfer-view",
        params: { transferUuid: item.uuid },
      });
    },
    onRowRightClicked(item) {
      this.selectedTransfer = item.uuid;
      this.openInNewTab();
    },
    onRowMiddleClicked(item) {
      this.selectedTransfer = item.uuid;
      this.openInNewTab();
    },
    openInNewTab() {
      const routeData = this.$router.resolve({
        name: "foravila-transfer-view",
        params: { transferUuid: this.selectedTransfer },
      });
      window.open(routeData.href, "_blank");
    },
    newItemButtonClicked() {
      this.$router.push({
        name: "foravila-new-transfer",
      });
    },
    getPickupDateTime(date, time) {
      if (!date) return null;

      let dateTimeString = [];
      if (date) dateTimeString.push(date.split("T")[0]);
      if (time) dateTimeString.push(time);

      if (!dateTimeString.length) return null;

      dateTimeString = dateTimeString.join("T");
      const formatting = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };

      return (
        formatDateStringToDate(dateTimeString, this.$i18n.locale, formatting) ||
        null
      );
    },
  },
};
</script>

<style lang="scss">
.clients-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
